import React from 'react';
import styled, { css } from 'styled-components';
import SEO from './seo';
import Layout from './layout';
import Nav from './nav';

export const ContentPageLayout = ({ title, seo, children }) => (
  <>
    <TopRightTriangleContainer>
      <TopRightTriangle />
    </TopRightTriangleContainer>
    <SEO title={(title && typeof title === 'string') ? title : null} {...(seo ?? {})} />
    <Layout>
      <Nav />
      <PageContainer>
        <Content>
          <Title>{title}</Title>
          {children}
        </Content>
      </PageContainer>
    </Layout>
  </>
);

const Title = styled.h1`
  padding: 0;
  margin: 0;
  padding-bottom: 1rem;
  font-family: Amatic SC, Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  font-size: 5rem;
  line-height: 5rem;
  letter-spacing: 0.035em;
  text-transform: uppercase;
  color: black;
  padding-top: 5rem;

  & h1, & h2, & h3, & h4, & h5, & h6 {
    display: inline-block;
    font-size: 5rem;
    margin: 0;
    padding: 0;
  }
`;

const PageContainer = styled.main`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 5rem;

  @media only screen and (max-width: 600px) {
    padding: 0 2rem;
  }
`;

const Content = styled.div`
  width: 100%;
  max-width: 70rem;
`;

const TopRightTriangleContainer = styled.div.attrs({ ariaHidden: true })`
  position: absolute;
  top: 0;
  width: 100vw;
  overflow-x: hidden;
  height: 20vw;
`;

const TopRightTriangle = styled.div`
  position: absolute;
  top: -10vw;
  right: -10vw;
  width: 20vw;
  height: 20vw;
  transform: rotate(45deg);
  background-color: #4A4969;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  z-index: 1;

  @media only screen and (min-width: 1200px) {
    width: 240px;
    height: 240px;
    top: -120px;
    right: -120px;
  }
`;

export default ContentPageLayout;