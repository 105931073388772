import React from "react"
import { Link } from "gatsby"

import styled from 'styled-components';
import meImg from '../images/me.png';
import ContentPageLayout from "../components/content-page-layout";
import SEO from "../components/seo";

const AboutPage = () => (
  <ContentPageLayout title="MORE ABOUT OOK.">
    <SEO title="About" />
    <p>I’m Patrick, a composer based in Texas writing original music for video games, film, and animation.</p>
    <p>I specialize in orchestral pieces of a casual, atmospheric, or cinematic style. My biggest influences are 
      the music of Animal Crossing, How To Train Your Dragon, and Studio Ghibli films.</p>
    {/* <p>I’m always looking for new and exciting projects to work on. If you’re interested in custom, professional audio for your work, 
      please find my contact information below. I would love to get in touch!</p> */}
    <ContactContainer>
      <Subtitle id="contact">Contact</Subtitle>
      <div style={{ paddingBottom: '.25rem' }}>email : <a href="mailto:patrick@ook.fm">patrick@ook.fm</a></div>
      <div>twitter : <a href="https://twitter.com/thisisook">@thisisook</a></div>
    </ContactContainer>
    <p><ProfileImg aria-hidden="true" alt="Me" src={meImg}/></p>
  </ContentPageLayout>
);



const ProfileImg = styled.img`
  width: 35%;
  min-width: 250px;
  max-width: 400px;
  user-select: none;
`;

const Subtitle = styled.h2`
  text-transform: lowercase;
  font-family: Sintony, Arial, Helvetica, sans-serif;
  font-size: 1.125rem;
  font-weight: 600;
  margin: 0;
  padding-bottom: .5rem;
`;

const ContactContainer = styled.div`
  margin-top: 2rem;
`;


export default AboutPage
